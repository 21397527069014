<template>
  <div class="position-relative" v-click-outside="hideDropdown">
    <v-text-field
      v-model="value"
      :disabled="disabled"
      outlined
      dense
      hide-details
      @focus="showDropdown"
      @keyup="getSuggestion"
      @blur="onInputBlur"
      class="c-input-small"
      :label="$t('labels.barcode_sku_name_description_size')"
      :placeholder="$t('labels.barcode_sku_name_description_size')"
    ></v-text-field>
    <div class="position-absolute result-list" v-if="dropdown">
      <template v-if="suggestions.length > 0">
        <div
          class="result-item"
          :class="{ 'selected-item': suggestion.id == selected.id }"
          v-for="suggestion in suggestions"
          :key="`s_${suggestion.id}`"
          @click="selectItem(suggestion)"
        >
          <div class="d-flex align-center">
            <div>
              {{ $t("labels.barcode") }}:
              <b>{{ suggestion.customer_goods_barcode }}</b> |
              {{ $t("labels.sku") }}: <b>{{ suggestion.sku }}</b> |
              {{ $t("labels.name") }}: {{ suggestion.name }} |
              {{ $t("labels.goods_description") }}:
              {{ suggestion.description }} | {{ $t("labels.size") }}:
              {{ suggestion.size }}
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="result-item font-italic">
          <div>
            {{
              !value ? $t("labels.enter_barcode_sku_name_description_size") : ""
            }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";

export default {
  name: "SelectSuggestGoodsLimit",
  components: {
    // ImageViewer: () => import('@/components/common/ImageViewer'),
  },
  props: {
    label: {
      type: String,
      default: () => null,
    },
    suggestionApiUrl: {
      type: String,
      default: () => null,
    },
    defaultValue: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    index: {
      type: Number,
      default: () => 0,
    },
    obj: {
      type: Object,
      default: () => {},
    },
    idWarehouse: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    value: null,
    dropdown: false,
    selected: {},
    suggestions: [],
    page: 1,
    totalPage: 1,
  }),
  computed: {},
  watch: {},
  created() {
    if (this.defaultValue) {
      this.value = this.defaultValue;
    }
  },
  mounted() {},
  methods: {
    onChange() {},
    getSuggestion: debounce(function () {
      httpClient
        .post("/goods/v1/suggest-goods-list", {
          keywords: this.value,
          page: this.page,
          id_warehouse: this.idWarehouse,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.suggestions = [...data.rows];
        });
    }, 500),
    onInputBlur() {
      this.$emit("onSelect", {
        item: {
          ...this.selected,
          customer_goods_barcode: this.value,
        },
        index: this.index,
        obj: this.obj,
      });
    },
    selectItem(item) {
      this.selected = { ...item };
      this.value = item.customer_goods_barcode;
      this.$emit("onSelect", {
        item: this.selected,
        index: this.index,
        obj: this.obj,
      });
      this.hideDropdown();
    },
    showDropdown() {
      this.dropdown = true;
    },
    hideDropdown() {
      this.dropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.result-list {
  width: 100%;
  background: #fff;
  z-index: 1;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  max-height: 280px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;

  .result-item {
    padding: 8px;
    border-bottom: 1px solid #f3f3f3;
    font-size: 12px;

    &:hover {
      background: #efefef;
      cursor: pointer;
    }

    &.selected-item {
      background-color: #eff6fd;
      color: #000000;
      font-weight: 600;
    }
  }
}
</style>
